<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">
        <el-date-picker v-model="searchForm.school_year" type="year" size="small" value-format="yyyy"
                        placeholder="选择学年" clearable>
        </el-date-picker>
        <el-input placeholder="批次号/批次名称" v-model="searchForm.keyword" size="small" class="input-with-select"
          style="width:200px;margin-left: 5px;" clearable>
        </el-input>
        <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
                   @click="() => { page.current_page = 1; getList() }">搜索
        </el-button>

        <el-button icon="el-icon-plus" style="margin-left:10px" size="small" type="primary"
                   @click="handleAdd">新增实习批次
        </el-button>
      </div>

      <div class="flexStart" style="color: #3378fa;font-size: 14px;font-weight: 700;cursor: pointer;flex-wrap: wrap;">
        <el-button v-if="$store.state.config.sync_data" icon="el-icon-refresh" size="small" type="primary"
                   @click="tongbuJava">迁移老版本
        </el-button>
      </div>

    </el-row>
    <div class="dividerBar"></div>

    <el-table v-tableFit :data="tableData" row-key="id" height="100%" size="small" stripe border
              style="width:fit-content;width:100%;">
      <el-table-column type="index" label="序号" width="55px" align="center"/>
      <el-table-column label="批次名称" prop="batch_name" min-width="200px"  show-overflow-tooltip align="center"/>
      <el-table-column label="批次号" prop="batch_code" show-overflow-tooltip width="200px" align="center"/>
      <el-table-column label="学年" prop="school_year" show-overflow-tooltip width="100px"/>
      <el-table-column label="开始时间" prop="start_time" min-width="100px"/>
      <el-table-column label="结束时间" prop="end_time"  min-width="100px"/>
      <el-table-column label="单位名称" prop="remark" show-overflow-tooltip min-width="200px"/>
      <el-table-column label="岗位名称" prop="remark" show-overflow-tooltip min-width="200px"/>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-tag class="pointer"
                  @click="handleModify(scope.row,'edit')" type="primary" size="mini" style="margin-left: 5px;">编辑</el-tag>
          <el-tag class="pointer" @click="handleDel(scope.row)" type="danger" size="mini" style="margin-left: 5px;">删除</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
                   :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
                   :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>

    <el-dialog ref="ruleForm" :visible.sync="dialogForm" :close-on-click-modal="false"
               custom-class="cus_dialog" >
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;">{{ modalTitle }}</span>
      </div>
      <el-form :model="formData" :rules="rules" ref="dynamicValidateForm" label-width="150px">
        <el-form-item class="is-required" label="批次名称" prop="batch_name">
          <el-input v-model="formData.batch_name"></el-input>
        </el-form-item>
        <el-form-item class="is-required" label="批次号" prop="batch_code">
          <el-input v-model="formData.batch_code"></el-input>
        </el-form-item>
        <el-form-item class="is-required" label="学年" prop="school_year">
          <el-date-picker v-model="formData.school_year" type="year" value-format="yyyy"
                          placeholder="选择学年" :clearable="false">
          </el-date-picker>
        </el-form-item>
        <el-form-item class="is-required" label="实习起止时间" prop="start_end_date">
          <el-date-picker
              v-model="formData.start_end_date"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item class="is-required" label="要求提交周报数" prop="complete_week">
          <el-input-number v-model="formData.complete_week" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item class="is-required" label="要求提交月报数" prop="complete_month">
          <el-input-number v-model="formData.complete_month" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item class="is-required" label="要求打卡数" prop="dk_count">
          <el-input-number v-model="formData.dk_count" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item class="is-required" label="每月要求巡防次数" prop="xf_count">
          <el-input-number v-model="formData.xf_count" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item label="指导老师" prop="mentor_name">
          <el-input v-model="formData.mentor_name"></el-input>
        </el-form-item>
        <el-form-item label="指导老师电话" prop="mentor_tel">
          <el-input v-model="formData.mentor_tel"></el-input>
        </el-form-item>
        <el-form-item label="批次说明" prop="description">
          <el-input @input="$forceUpdate()" type="textarea" v-model="formData.description"
                    :autosize="{ minRows: 2 }"></el-input>
        </el-form-item>
      </el-form>
      <div class="" slot="footer">
        <el-button @click="submitForm(0)" type="primary">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tchtree from "../com/tchTree.vue";
import moment from "moment";

export default {
  components: {tchtree},
  data() {
    var validateTel = (rule, value, callback) => {
      let regex = /^((0\d{2,3}(-)?\d{7,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/
      if (value != "" && !regex.test(value)) {
        callback(new Error("电话格式不正确"))
      } else {
        callback()
      }
    }
    return {
      activeName: "first",
      isRevoke: false,
      searchForm: {
        keyword: "",
        status: "",
        dept_id: "",
      },
      classArr: [],
      tableData: [],
      dialogForm: false,
      modalTitle: "添加",
      formData: {
        complete_week: 1,
        complete_month: 1,
        dk_count: 1,
        xf_count: 1,
      },
      sxqx: ["实习", "参军", "升学/升本", "出国", "长病假", "实习结束"],
      qyxz: [], qyxzObj: {},
      salary: [], salaryObj: {},
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      overlap: null,
      spword: "",
      sxqxArr: [], sxqxObj: {},
      applyList: [],
      dialogExportFormVisible: false,
      dialogChangeVisible: false,
      export_format: "word",
      changeList: [],
      dateRangeArr: [],
      checkBtn: false,
      rules: {
        batch_name: [{required: true, message: '请输入批次名称', trigger: 'blur'}],
        batch_code: [{required: true, message: '请输入批次号，字母或数字组成', trigger: 'blur'}],
        school_year: [{required: true, message: '请选择学年', trigger: 'change'}],
        start_end_date: [{required: true, message: '请选择实习起止时间', trigger: 'change'}],
        mentor_tel: [{validator: validateTel, trigger: 'blur'}],
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleClick(tab, event) {

      for (let item of this.applyList) {
        if ("tab" + item.id == this.activeName) {
          this.formData = item;
        }
      }
    },
    getComType() {
      return new Promise(resolve => {
        this.$http
            .post("/api/sys_param_list", {
              param_type: "'ENTER_INFO','SALARY_RANGE','SHIXI_STATUS'",
              used: 0
            })
            .then(res => {
              let qyxz = [];
              let salary = [];
              let sxqxArr = [];
              let qyxzObj = {};
              let salaryObj = {};
              let sxqxObj = {};
              for (let item of res.data) {
                if (item.param_type == "ENTER_INFO") {
                  qyxz.push(item);
                  qyxzObj[item.param_value] = item.param_desc
                }
                if (item.param_type == "SALARY_RANGE") {
                  salary.push(item);
                  salaryObj[item.param_value] = item.param_desc
                }
                if (item.param_type == "SHIXI_STATUS") {
                  sxqxArr.push(item);
                  sxqxObj[item.param_value] = item.param_desc
                }
              }
              this.qyxz = qyxz;
              this.salary = salary;
              this.sxqxArr = sxqxArr;
              this.qyxzObj = qyxzObj;
              this.salaryObj = salaryObj;
              this.sxqxObj = sxqxObj;
              console.log(qyxzObj)
              resolve();
            });
      });
    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
    handleAdd() {
      this.dialogForm = true;
      this.modalTitle = "新增实习批次";
      this.formData = {
        complete_week: 1,
        complete_month: 1,
        dk_count: 1,
        xf_count: 1,
      }
      this.resetForm()
    },
    getList() {
      let data = {};
      data.keyword = this.searchForm.keyword;
      data.school_year = this.searchForm.school_year;
      data.page = this.page.current_page;
      data.pagesize = this.page.per_page;
      this.$http.post("/api/fieldwork_batch_list", data).then(res => {
        this.tableData = res.data.data;
        this.page = res.data.page;
      });
    },
    handleDel(row) {
      this.$confirm('此操作不可恢复，确定删除？', {
        type: 'warning'
      }).then(res => {
        this.$http
            .post("/api/fieldwork_batch_delete", {
              id: row.id
            })
            .then(res => {
              this.$message.success("删除成功");
              this.getList();
            });
      })
    },
    handleModify(row) {
      this.modalTitle = "编辑实习批次"
      this.formData = {...row}
      this.formData.start_end_date = [row.start_time,row.end_time]
      this.dialogForm = true
    },
    submitForm(st) {
      this.$refs.dynamicValidateForm.validate((valid) => {
        if (valid) {
          let formData = {...this.formData}
          formData.start_time = formData.start_end_date[0]
          formData.end_time = formData.start_end_date[1]
          this.$http.post('/api/fieldwork_batch_edit', formData).then(res => {
            this.$message.success('提交成功')
            this.getList()
            this.dialogForm = false
          })
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.dynamicValidateForm.resetFields();
    },
    uploadFiles(e) {
      this.$http
          .post("/api/import_stu_enter_xls", {
            url: e.src
          })
          .then(res => {
            this.$message.success("导入成功");
            if (res.data.errmsg && res.data.errmsg.length > 0) {
              let html = "";
              for (let msg of res.data.msg) {
                html +=
                    "<div style='font-size:12px'>" +
                    msg["姓名"] +
                    ":" +
                    msg.msg +
                    "</div>";
              }
              this.$alert(html, "未导入数据", {
                dangerouslyUseHTMLString: true
              });
            }
            this.getList();
          });
    },
    tongbuJava() {
      this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let loadingInstance = this.$loading({
          lock: true,
          text: "已发送请求，请稍等...",
          spinner: 'el-icon-loading'
        })
        this.$http.post("/api/sync_fieldwork_batch", {}).then(res => {
          loadingInstance.close()
          this.$message.success('后台同步中，请刷新查看')
          this.getList();
        }).catch(err => {
          this.$message.error('同步失败')
          loadingInstance.close()
        })

      }).catch(() => {

      });
    },
    showChangeList(row) {
      this.$http.post("/api/t_sch_stu_enter_from_list", {stu_id: row.stu_id, check_status: 1}).then(res => {

        this.changeList = res.data;
        this.dialogChangeVisible = true
      })
    },
    getCls(data, id) {
      for (let item of data) {
        if (item.id === Number(id)) {
          return item
        }
        if (item.children && item.children.length) {
          return this.getCls(item.children, id)
        }
      }
    },
    clsExportExcel() {
      this.export_format = 'xls'
      this.exportWordOrPdf()
    },
    exportToPDF(row) {
      this.$http.post(this.$pdfUrl + '/exportTeacherLedger', {
        pmid: 221,
        latest: 1,
        stuArr: [{id:row.id,name:row.username}]
      }).then(res => {
        window.open(res.data.url, "_blank")
      })
    },
    addItem(type) {
      this.details[type].push({
        sTime: "",
        eTime: "",
        content: "",
        dateRange: [],
      })
    },
    delItem(type,index) {
      this.details[type].splice(index, 1)
    },
  }
};
</script>

<style scoped lang="less">
</style>